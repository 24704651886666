// import dynamic from 'next/dynamic';
import { useGetGravityFormQuery } from '@/generated/graphql';
import GravityForm from '@/components/Modules/Forms/GravityForm';
import { useRouter } from 'next/router';

export default function FormLoader({ id, match, footer, staffNameFields }) {
    const router = useRouter();
    const fieldValues = match?.[7];
    const params = router?.asPath.split('?')[1];
    const splitObject = [];
    if (fieldValues) {
        const split = fieldValues.split(/[=;]/);
        for (let i = 0; i < split.length; i += 2) {
            splitObject.push({
                dynamicKey: split[i]?.trim(),
                dynamicValue: split[i + 1]?.trim()
            });
        }
    }
    const dynamicParameters = [...(staffNameFields || []), ...(params ? [params] : []), ...splitObject];

    const { data, loading, error } = useGetGravityFormQuery({ variables: { id: id } });

    if (loading) return 'Loading...';
    if (error) {
        console.error(error);
        return null;
    }
    const form = data?.gfForm;

    return <GravityForm footer={footer} form={form} dynamicParameters={dynamicParameters} />;
}
