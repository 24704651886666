import { useMutation, gql } from '@apollo/client';
// import dynamic from 'next/dynamic';
import styles from './GravityFormsForm.module.sass';
import React, { useState, useCallback, useEffect } from 'react';
import cx from 'classnames';
import { GfForm as GravityFormsFormType, FormField, FieldError } from '@/generated/graphql';
import useGravityForm from '@/hooks/useGravityForm';
import GravityFormsField from './GravityFormsField';
import useFormConditionalLogic from '@/hooks/useFormConditionalLogic';
import { useRouter } from 'next/router';
import { showField } from '@/utils/showField';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { ReCaptchaProvider, useReCaptcha } from 'next-recaptcha-v3';
import { showConfirmationMessage } from './showConfirmationMessage';

import Container from '@/components/Container';

// took out this var fomr submitForm( $file: Upload! )
const SUBMIT_FORM = gql`
    mutation submitForm($formId: ID!, $fieldValues: [FormFieldValuesInput]!) {
        submitGfForm(input: { id: $formId, fieldValues: $fieldValues }) {
            entry {
                id
                formId
            }
            errors {
                id
                message
            }
        }
    }
`;

interface Props {
    form: GravityFormsFormType;
    subLayout: any;
    subBackground: any;
    subHeader: any;
    footer: boolean;
    dynamicParameters: any;
}

export default function GravityFormsForm({ form, dynamicParameters }: Props) {
    const [submitForm, { data, loading, error }] = useMutation(SUBMIT_FORM);
    const [logicFieldInfo, setLogicFieldInfo] = useState([]);
    // const [wasSuccessfullySubmitted, setWasSuccessfullySubmitted] = useState(false);
    // const gfWrapper = document?.querySelector('.gform_wrapper');
    // console.log('gd', gfWrapper);

    const haveEntryId = Boolean(data?.submitGfForm?.entry?.id);
    const haveFieldErrors = Boolean(data?.submitGfForm?.errors?.length);
    const wasSuccessfullySubmitted = haveEntryId && !haveFieldErrors;
    const defaultConfirmation = form?.confirmations?.find(confirmation => confirmation?.isDefault);
    const conditionalLogicConfirmations = form?.confirmations?.filter(confirmation => confirmation?.isActive && !confirmation?.isDefault);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const formFields = form?.formFields?.nodes || [];
    const formId = form?.formId || null;
    const formTitle = form?.title || 'Untitled Form: ' + form?.formId;
    // const reRef: any = React.createRef();
    const router = useRouter();
    let dynamicParametersCombined = [...dynamicParameters] || [];
    const formFieldLogic = useFormConditionalLogic(formFields, logicFieldInfo);
    const queryParams = router?.asPath.split('?')[1];

    const { state } = useGravityForm();

    useEffect(() => {
        const errorMessageElement = document.querySelector(`#gform_wrapper_${formId} .error-message`);
        if (errorMessageElement) {
            errorMessageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [haveFieldErrors]);

    const ReCaptchaComponent = () => {
        const { executeRecaptcha } = useReCaptcha();

        // Create an event handler so you can call the verification on button click event or form submit
        const handleReCaptchaVerify = useCallback(
            async event => {
                event?.preventDefault();

                if (!executeRecaptcha) {
                    console.warn('Execute recaptcha not yet available');
                    return;
                }
                if (loading) return;
                const form_action = formTitle
                    .trim()
                    .replace(/[^a-z0-9]/gi, '')
                    .toLowerCase();
                const token = await executeRecaptcha(form_action);

                if (token) {
                    submitForm({
                        variables: {
                            formId,
                            fieldValues: state
                        }
                    }).catch(error => {
                        console.error(error);
                    });
                }
            },
            [executeRecaptcha]
        );

        // You can use useEffect to trigger the verification as soon as the component being loaded
        // useEffect(() => {
        //     handleReCaptchaVerify();
        // }, [handleReCaptchaVerify]);

        return (
            <div className="submit-button-container text-center md:text-left">
                <button onClick={handleReCaptchaVerify} disabled={loading} className={`btn ${!loading ? 'btn-blue hover:bg-darkBlue' : 'btn-gray'}`}>
                    {loading ? 'Submitting' : form?.button?.text || 'Submit'}
                </button>
            </div>
        );
    };

    if (queryParams) {
        let querySplit = queryParams.split(/[=|&]/);
        for (let i = 0; i < querySplit.length; i++) {
            if (i % 2 === 0) {
                dynamicParametersCombined.push({ dynamicKey: querySplit[i], dynamicValue: querySplit[i + 1] });
            }
        }
    }

    // async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    //     event.preventDefault();

    // }

    function getFieldErrors(id: number): FieldError[] {
        if (!haveFieldErrors) return [];
        return data.submitGfForm.errors.filter((error: FieldError) => error.id === id);
    }

    if (wasSuccessfullySubmitted) {
        const conditionalConfirmationMessage = showConfirmationMessage(state, conditionalLogicConfirmations);
        const confirmationMessage = conditionalConfirmationMessage?.message || defaultConfirmation?.message;
        const type = conditionalConfirmationMessage?.type || defaultConfirmation?.type;
        const url = conditionalConfirmationMessage?.url || defaultConfirmation?.url;
        const pageUri = conditionalConfirmationMessage?.page?.node?.uri || defaultConfirmation?.page?.node?.uri;

        if (type === 'REDIRECT') router.push(url);
        else if (type === 'PAGE') router.push(pageUri);
        else {
            return (
                <Container className="form-confirmation" width="narrow">
                    {confirmationMessage ? (
                        <div id={`gform_confirmation_wrapper_${formId}`} className={styles.gformConfirmationWrapper}>
                            <div
                                id={`gform_confirmation_message_${formId}`}
                                className={`gform_confirmation_message_${formId} gform_confirmation_message content-container`}
                                dangerouslySetInnerHTML={{ __html: confirmationMessage }}
                            />
                        </div>
                    ) : (
                        'Form successfully submitted - thank you.'
                    )}
                </Container>
            );
        }
    }

    function handleFormValueChange(fieldVal, id): void {
        setLogicFieldInfo(logicFieldInfo => [...logicFieldInfo, { fieldId: id, value: fieldVal }]);
    }

    return (
        <ReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_CAPTCHA} strategy="lazyOnload">
            <form method="post" id={`gform_wrapper_${formId}`} className={cx([form?.cssClass, 'gform_wrapper'])}>
                <div className="form-fields md:flex md:flex-wrap">
                    {formFields.map(field => {
                        const showFieldLogic = showField(field?.id, formFieldLogic);
                        return (
                            <GravityFormsField
                                params={dynamicParametersCombined}
                                key={field?.id}
                                field={field as FormField}
                                fieldErrors={getFieldErrors(Number(field?.id))}
                                handleFormValueChange={handleFormValueChange}
                                showField={showFieldLogic}
                                formId={formId}
                            />
                        );
                    })}
                </div>
                {error ? <p className="error-message">{error.message}</p> : null}
                {form && <ReCaptchaComponent />}
                <div id={`gform_verify_wrapper_${formId}`} />
            </form>
            {/* <style jsx>
                {`
                    .gform_confirmation_wrapper {
                        background: #1294d8;
                        padding: 20px;
                        border-radius: 3px;
                        color: #fff;
                        text-align: center;
                    }
                    .submit-button-container {
                        text-align: center;
                    }
                `}
            </style> */}
        </ReCaptchaProvider>
    );
}
